// extracted by mini-css-extract-plugin
export var description = "Facility-module--description--Xdoc4";
export var facility = "Facility-module--facility--N5K9v";
export var fadeDown = "Facility-module--fadeDown--9T3EG";
export var fadeUp = "Facility-module--fadeUp--s0QxV";
export var flowing = "Facility-module--flowing---0hwK";
export var image = "Facility-module--image--HAjjl";
export var info = "Facility-module--info--86VRF";
export var lineIn = "Facility-module--lineIn--CVZiE";
export var other = "Facility-module--other--JAYnp";
export var title = "Facility-module--title--Ebtjj";