import * as React from 'react'

import * as styles from '@/components/Kitchen/Ability/Ability.module.scss'
import MediaBreak from '@/components/Parts/MediaBreak'

const KitchenAbility = () => {
  return (
    <section className={styles['ability']}>
      <h3 className={styles['title']}>
        La Tempoキッチン
        <MediaBreak media='mobile' />
        だからできること｡
      </h3>
      <div className={styles['container']}>
        <div className={styles['item']}>
          <span className={styles['num']}>01</span>
          <div className={styles['head']}>
            イチから
            <MediaBreak media='desktop' />
            始める人の
            <MediaBreak media='desktop' />
            不安を軽減｡
          </div>
          <div className={styles['divider']} />
          <div className={styles['description']}>
            飲食店開業時に掛かる膨大な初期投資なしで、好きなタイミングで自分のお店を持てます。既に店舗を持っている方も新商品のテスト販売などにオススメです。惣菜販売やカフェができる「飲食店営業」の許可を取得しています。志と腕さえあれば誰でもスタートを切ることができます。
          </div>
        </div>
        <div className={styles['item']}>
          <span className={styles['num']}>02</span>
          <div className={styles['head']}>
            出店の宣伝を
            <MediaBreak media='desktop' />
            積極的に
            <MediaBreak media='desktop' />
            お手伝い｡
          </div>
          <div className={styles['divider']} />
          <div className={styles['description']}>
            あなたの出店情報をLa
            Tempoのカレンダーやお知らせに掲載、各種SNSでも発信します。また、複合施設La
            Tempoならではの魅力として、コミュニティスペースや宿泊利用客からの自然な流入も見込めます。
          </div>
        </div>
        <div className={styles['item']}>
          <span className={styles['num']}>03</span>
          <div className={styles['head']}>
            シンプルな
            <MediaBreak media='desktop' />
            料金設定で
            <MediaBreak media='desktop' />
            貸し出し｡
          </div>
          <div className={styles['divider']} />
          <div className={styles['description']}>
            営業時間は10:00〜17:00。料金は出店回数×5,500円のみのシンプルな価格設定です。契約期間や出店回数による縛りなどもございません。登録手数料は無料、問い合わせ後の面談と利用契約が必要です。
          </div>
        </div>
      </div>
    </section>
  )
}

export default KitchenAbility
