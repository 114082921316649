import * as React from 'react'

import * as styles from '@/components/Kitchen/Entry/Entry.module.scss'

const KitchenEntry = () => {
  return (
    <section className={styles['entry']}>
      <h3 className={styles['title']}>出店者さま、募集中です｡</h3>
      <a className={styles['button']} href='mailto:latempo2022@gmail.com'>
        出店者登録　→
      </a>
    </section>
  )
}

export default KitchenEntry
