// extracted by mini-css-extract-plugin
export var caution = "Information-module--caution--WtvPl";
export var description = "Information-module--description--4lVcK";
export var detail = "Information-module--detail--vxKOJ";
export var fadeDown = "Information-module--fadeDown--TkCmQ";
export var fadeUp = "Information-module--fadeUp--8-Qo2";
export var flowing = "Information-module--flowing--mbxtT";
export var group = "Information-module--group--EWJPI";
export var head = "Information-module--head--nkAZs";
export var information = "Information-module--information--Avn1H";
export var label = "Information-module--label--UQnyq";
export var lineIn = "Information-module--lineIn--SIOG1";
export var title = "Information-module--title--a3c2A";