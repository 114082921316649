import * as React from 'react'

import * as styles from '@/components/Kitchen/Information/Information.module.scss'

const KitchenInformation = () => {
  return (
    <section className={styles['information']}>
      <h3 className={styles['title']}>Information</h3>
      <p className={styles['description']}>
        こちらはシェアキッチンをご利用する際に必要な情報です。
      </p>
      <div>
        <div className={styles['group']}>
          <span className={styles['label']}>料金</span>
          <div>
            <span className={styles['head']}>1回 5,500円</span>
            <p className={styles['detail']}>
              長期出店の相談も受け付けております。
            </p>
          </div>
        </div>
        <div className={styles['group']}>
          <span className={styles['label']}>時間</span>
          <div>
            <span className={styles['head']}>
              10:00〜18:00<span>（L.O.16:30）</span>
            </span>
            <p className={styles['detail']}>営業時間は11:00〜17:00です。</p>
          </div>
        </div>
      </div>
      <p className={styles['caution']}>
        ※１
        <br />
        登録手数料は無料、契約期間や出店回数による縛りはありません。1回からでもご利用可能です。少しでも興味がある方はぜひ見学にいらしてください。キッチンスペースへご案内します。また気になることがあれば気軽にお問い合わせください。
        <br />
        <br />
        ※２
        <br />
        登録後のご利用は事前予約制です。出店予約は、問合せと同じメールアドレスまたは各種SNSのDMにて受け付けています。ご連絡は出店したい日の１ヶ月前までにお願いします。早期予約はいつでもどうぞ。
        <br />
        <br />
        ※３
        <br />
        La
        Tempoキッチンは「菓子製造業」の許可を取得していません。ここで作った焼き菓子やパンは店内提供のみ可能です。別店舗やネットでの販売はできません。一方で、他の「菓子製造業」許可のある施設で作った焼き菓子やパンの持ち込み･販売は可能です。注文受付後のテイクアウトは可能です。
      </p>
    </section>
  )
}

export default KitchenInformation
