// extracted by mini-css-extract-plugin
export var ability = "Ability-module--ability--NIwsJ";
export var container = "Ability-module--container--6tMoR";
export var description = "Ability-module--description--Y4sxl";
export var divider = "Ability-module--divider--wI12C";
export var fadeDown = "Ability-module--fadeDown--G2EKN";
export var fadeUp = "Ability-module--fadeUp--P9h-I";
export var flowing = "Ability-module--flowing--vmasS";
export var head = "Ability-module--head--1dN8m";
export var item = "Ability-module--item--W-9Je";
export var lineIn = "Ability-module--lineIn--XSypF";
export var num = "Ability-module--num--0Nvz6";
export var title = "Ability-module--title--EZOfP";