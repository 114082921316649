import * as React from 'react'

import type { HeadFC, PageProps } from 'gatsby'

import SEO from '@/components/Base/SEO'
import KitchenAbility from '@/components/Kitchen/Ability'
import KitchenEntry from '@/components/Kitchen/Entry'
import KitchenFacility from '@/components/Kitchen/Facility'
import KitchenInformation from '@/components/Kitchen/Information'
import KitchenMain from '@/components/Kitchen/Main'
import KitchenPerson from '@/components/Kitchen/Person'
import Layout from '@/components/Layout'
import PageTitle from '@/components/Parts/PageTitle'

type PageContext = {
  breadcrumb: {
    crumbs: {
      pathname: string
      crumbLabel: string
    }[]
  }
}

const KitchenPage = ({
  pageContext: {
    breadcrumb: { crumbs },
  },
}: PageProps<Queries.SitePage, PageContext>) => {
  return (
    <Layout logo={true} dark={true}>
      <main>
        <PageTitle
          title='つくる、販売する｡'
          page='kitchen'
          curtain={false}
          crumbs={crumbs}
          crumbLabel='Share kitchen'
        />
        <KitchenMain />
        <KitchenAbility />
        <KitchenPerson />
        <KitchenFacility />
        <KitchenInformation />
        <KitchenEntry />
      </main>
    </Layout>
  )
}

export default KitchenPage

export const Head: HeadFC = ({ location }) => (
  <SEO title='つくる、販売する｡' pathname={location.pathname} page='kitchen' />
)
