// extracted by mini-css-extract-plugin
export var container = "Main-module--container--u-Syk";
export var description = "Main-module--description--O-Tef";
export var fadeDown = "Main-module--fadeDown--iLkNN";
export var fadeUp = "Main-module--fadeUp--uBMEC";
export var flowing = "Main-module--flowing--9HLO0";
export var image = "Main-module--image--AzJuO";
export var imageWrap = "Main-module--imageWrap--Ss1uA";
export var lineIn = "Main-module--lineIn--CXfhy";
export var main = "Main-module--main--qgNWQ";
export var title = "Main-module--title--Y32rU";