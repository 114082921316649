import * as React from 'react'

import * as styles from '@/components/Kitchen/Person/Person.module.scss'

const KitchenPerson = () => {
  return (
    <section className={styles['person']}>
      <h3 className={styles['title']}>こんな人に使ってほしい｡</h3>
      <p className={styles['description']}>
        新しいことに挑戦したい人。
        <br />
        地域や住人に愛着をもっている人。
        <br />
        食のちからで誰かを幸せにしたい人。
        <br />
        設備投資で開業または店舗拡大を足踏みしてる人。
        <br />
        本業とは別で空いてる時間に自分のお店を持ちたい人。
      </p>
      <a className={styles['button']} href='mailto:latempo2022@gmail.com'>
        出店者登録　→
      </a>
    </section>
  )
}

export default KitchenPerson
